//import 'bootstrap';
$(document).ready(() => {

    const hamb = document.querySelector('#hamb')
    const mainNav = document.querySelector('#main-nav')
    const overlay = document.querySelector('#overlay')
    const closeMenuBtn = document.querySelector('#close-menu')
    const searchBtn = document.querySelector('#search-btn')
    const closeSearchBtn = document.querySelector('#close-search-btn')
    const searchModal = document.querySelector('#search-modal')

    // floating labels for gform inputs with top label
    $('form .top_label .gfield .ginput_container input, form .top_label .gfield .ginput_container textarea').on('focus',function() {
        console.log('focusing')
        $(this).parent().parent('.gfield').addClass('active')
    });

    $('form .top_label .gfield .ginput_container input, form .top_label .gfield .ginput_container textarea').on('blur',function() {
        if ($(this).val().length == 0) {
            $(this).parent().parent('.gfield').removeClass('active');
          }
    });

    hamb.addEventListener('click',function(e){
        e.stopPropagation()
        openMenu()
    }, false)

    closeMenuBtn.addEventListener('click',function(e){
        e.stopPropagation()
        closeMenu()
    }, false)

    searchBtn.addEventListener('click',function(e){
        e.stopPropagation()
        openSearch()
    }, false)

    closeSearchBtn.addEventListener('click',function(e){
        e.stopPropagation()
        closeSearch()
    }, false)

    // opens the search
    function openSearch() {
        console.log('opensearch function')
        searchModal.classList.remove('hidden')
    }

      // closes the search
    function closeSearch() {
        searchModal.classList.add('hidden')
    }

    // opens main nav
    function openMenu() {
        console.log('opening nav')
        mainNav.classList.remove('translate-x-full')
        overlay.classList.remove('hidden')
    }

    // closes main nav
    function closeMenu() {
        console.log('closing nav')
        mainNav.classList.add('translate-x-full')
        overlay.classList.add('hidden')
    }


    // animate on scroll library
    AOS.init();

    var dropdowns = document.querySelectorAll('div.dropdown');
    var viewportWidth = $(window).width();
    console.log(viewportWidth)

    $('body').click(function () {
        //closeSearch();
        closeAllDropDowns();
    })

    $(window).scroll(function () {
        if (viewportWidth <= 1023) {
            console.log('mobile scroll')
            return;
        } else {
            console.log('desktop scroll')
            closeSearch();
            closeAllDropDowns();
        }
    })


    // $('#searchHolderDesktop button').click(function (e) {
    //     e.stopPropagation()
    //     openSearch();
    // });

    // $('#searchHolderDesktop form').click(function (e) {
    //     e.stopPropagation()
    //     //openSearch();
    // });



    // function openSearch() {
    //     $('#searchHolderDesktop').addClass('active')
    //     $('#searchHolderDesktop form input.search-field').focus()
    //     $('.topnav a, .topnav .pipe').each(function () {
    //         $(this).addClass('hidden')
    //     })
    // }

    // function closeSearch() {
    //     $('#searchHolderDesktop').removeClass('active');
    //     $('#searchHolderDesktop form input.search-field').blur()
    //     $('.topnav a, .topnav .pipe').each(function () {
    //         $(this).removeClass('hidden')
    //     })
    // }

    function closeAllDropDowns() {
        $('header').removeClass('active');
        $('.underlay').removeClass('active');
        $(dropdowns).each(function () {
            $(this).removeClass('open')
        });
    }

    // on resize, reset this wiewport width

    if (viewportWidth <= 1023) {
        console.log('mobile view')

        //$("header .dropdown a").click(function(e){e.stopPropagation()})
        $("header .dropdown a").click(function (e) {

            e.stopPropagation()
            console.log('click click')
            closeAllDropDowns();
            //$('header').addClass('active'); $('.underlay').addClass('active');
            $(this)
                .parent()
                .addClass('open');
        });

    } else {
        $("header div.menu-item:not(.dropdown)")
            .mouseenter(function () {
                closeAllDropDowns();
            });
        $("header .dropdown a").click(function (e) {
            e.stopPropagation()
        })
        $("header .dropdown").mouseenter(function () {
            //console.log('header .dropdown mouseenter') // added
            closeAllDropDowns();
            $('header').addClass('active');
            $('.underlay').addClass('active');
            //console.log($(this).parents('.relative')) // added
            $(this).closest('div.relative').addClass('open');
        });

    };


});
